<template>
	<div class="solution_page">
		<div class="content">
			<div class="breadcrumb_box flex-center">
				<Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
			</div>
			<div class="banner">
				<img class="icon_banner" :src="scenesUrl" alt="" />
			</div>
			<div
				class="classify flex-center"
				:class="isMore == true ? 'more_list' : ''"
			>
				<span class="more display pointer" @click="isMore = !isMore">
					{{ isMore == true ? '收起' : '更多' }}</span
				>
				<div
					class="classify_list display pointer nowrap"
					:class="classifyIndex == index ? 'classifyActive' : ''"
					v-for="(item, index) in classifyList"
					:key="index"
					@click="changeBtn(item, index)"
				>
					{{ item.solutionName }}
				</div>
			</div>
			<div class="scene Between flex-wrap">
				<div
					class="sceneLIst pointer"
					v-for="(item, index) in sceneLIst"
					:key="index"
					@click="toDetails(item)"
				>
					<img class="scene_img" :src="item.icon" alt="" />
					<div class="scene_name Between">
						<div class="name">{{ item.solutionName }}</div>
						<img
							class="icom_img icon_n"
							src="@/assets/img/solution/icon_n.png"
							alt=""
						/>
						<img
							class="icom_img icon_y"
							src="@/assets/img/solution/icon_y.png"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Breadcrumb from '@/components/breadcrumb'
export default {
	name: 'solution',
	components: {
		Breadcrumb,
	},
	data() {
		return {
			isMore: false,
			classifyIndex: 0,
			classifyList: [],
			sceneLIst: [],
			breadcrumbList: [
				{
					name: '解决方案',
					url: '',
				},
			],
			scenesUrl: '',
			params: {
				twoSolutionId: '',
			},
		}
	},
	watch: {
		'$store.state.typeId'() {
			this.init()
		},
	},
	created() {
		this.init()
	},

	methods: {
		init() {
			this.classifyIndex = 0
			let navBarData = JSON.parse(localStorage.getItem('navBarData'))
			this.breadcrumbList = [
				{
					name: '解决方案',
					url: '',
				},
				{
					name: navBarData.fatherName,
					url: '',
				},
				{
					name: navBarData.name,
					url: '',
				},
			]
			this.getTwoSolution(navBarData.id)
		},
		//获取二级数据
		getTwoSolution(id) {
			let params = {
				oneSolutionId: id,
			}
			this.api.findTwoSolution(params).then((res) => {
				if (res.code == 0) {
					this.classifyList = res.data
					if (res.data.length != 0) {
						this.scenesUrl = res.data[0].icon
					} else {
						this.scenesUrl = ''
					}
					this.getThreeSolution(res.data[0].id)
				}
			})
		},
		//获取三级数据
		getThreeSolution(twoSolutionId) {
			this.api
				.findThreeSolution({ twoSolutionId: twoSolutionId })
				.then((res) => {
					if (res.code == 0) {
						this.sceneLIst = res.data
					}
				})
		},
		changeBtn(item, index) {
			console.log(item)
			this.classifyIndex = index
			this.scenesUrl = item.icon
			this.getThreeSolution(item.id)
		},
		toDetails(item) {
			this.$router.push({
				path: '/solutionDetails',
				query: {
					id: item.id,
					name: item.solutionName,
					fatherName: this.$route.query.name,
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.solution_page {
	.banner {
		.icon_banner {
			width: 100%;
			height: 440px;
		}
	}
	.more_list {
		flex-wrap: wrap;
	}
	.classify {
		width: 1200px;
		background: #4a4a4a;
		position: relative;
		overflow: hidden;
		.classify_list {
			height: 44px;
			padding: 0px 30px;
			font-size: 16px;
			color: #ffffff;
		}

		.more {
			width: 60px;
			height: 44px;
			background: #4a4a4a;
			font-size: 16px;
			color: #ffffff;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	.classifyActive {
		background: #e62129 !important;
	}
	.scene {
		margin-top: 40px;
		padding-bottom: 40px;
		.sceneLIst:hover {
			.scene_name {
				background: #e62129 !important;
				.name {
					color: white !important;
				}
				.icon_n {
					display: none !important;
				}
				.icon_y {
					display: block !important;
				}
			}
		}
		.sceneLIst {
			width: 580px;
			height: 400px;
			border: 1px solid rgba(222, 222, 222, 0.96);
			margin-bottom: 34px;
			box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);

			.scene_img {
				width: 578px;
				height: 330px;
			}
			.scene_name {
				height: 69px;
				background: white;
				padding-left: 37px;
				padding-right: 46px;
				box-sizing: border-box;
				margin-top: -5px;
				.name {
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #4a4a4a;
				}
				.icom_img {
					width: 47px;
					height: 33px;
				}
				.icon_n {
					display: block;
				}
				.icon_y {
					display: none;
				}
			}
		}
	}
}
</style>